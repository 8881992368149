class ZIDXAccountDataFeedStatus implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountDataFeedStatusContainer";
    }
    getMatchURL(){
        return "/account/data-feeds/status";
    }
    stopReload=false;
    render(){
        // console.log("data feed status");
        let self=this;
        ZIDX.$(".zidxAccountDataFeedStatusContainer.zidxPageActive.a").on("click", function(){
            self.stopReload=true;
        });
        setTimeout(function(){
            self.updateData();
        }, 3000);
    }
    updateData() {
        let self=this;
        if(self.stopReload){
            return;
        }
        if (ZIDX.$(".zidxAccountDataFeedStatusContainer.zidxPageActive").length > 0) {
            let obj = {
                id: "ajaxDataFeedStatus",
                method: "get",
                postObj: {},
                ignoreOldRequests: false,
                callback: function (r: string) {
                    if(self.stopReload){
                        return;
                    }
                    let js = JSON.parse(r);
                    if (js.success) {
                        ZIDX.$(".feedStatusDynamicDiv", ZIDX.$(".zidxAccountDataFeedStatusContainer.zidxPageActive")).html(js.data);
                    } else {
                        ZIDX.$(".feedStatusDynamicDiv", ZIDX.$(".zidxAccountDataFeedStatusContainer.zidxPageActive")).html("Failed to retrieve status");
                    }
                    setTimeout(function(){
                        if(self.stopReload){
                            return;
                        }
                        self.updateData();
                    }, 3000);
                },
                errorCallback: function (r: string) {
                    ZIDX.$(".feedStatusDynamicDiv").html("Failed to retrieve status, there may be a network problem.");
                    setTimeout(function(){
                        self.updateData();
                    }, 3000);
                },
                url: window.zidxBase.urlAppend(window.location.href,"liveUpdate=1")
            };
            ZIDX.ajaxRequest(obj);
        }
    }
}